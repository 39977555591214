import { Outlet } from 'react-router-dom';
import Cookies from 'js-cookie';

import BackgroundFlights from 'images/background-flights.png';

import S from 'theme/structure';
import { useEffect, useState } from 'react';

import { SabreMock } from 'component/api';

const {
    Body: { Container, Background },
} = S;

let backgroundImage = BackgroundFlights;

const style = {
    backgroundImage: backgroundImage ? `url(${backgroundImage})` : '',
};

const sabreEasterEgg = {
    width: 200,
    height: 200,
    position: 'fixed',
    bottom: 0,
    right: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
};

const Component = () => {
    const [counter, setCounter] = useState(0);
    const [sabreCounter, setSabreCounter] = useState(0);

    const defaultSabreResponse = { error: { status: 'empty' } };
    const [sabreResponse, setSabreResponse] = useState(defaultSabreResponse);

    useEffect(() => {
        if (counter === 5) {
            const action =
                localStorage.getItem('showDebug') === 'show'
                    ? 'disable'
                    : 'enable';

            action === 'enable'
                ? localStorage.setItem('showDebug', 'show')
                : localStorage.removeItem('showDebug');

            setCounter(0);

            window.alert('Debug mode ' + action + 'd');
        }
    }, [counter]);

    useEffect(() => {
        if (sabreCounter === 5) {
            const identifier = Cookies.get('identifier');
            const oneclick = true;
            if (!identifier) {
                alert('No identifier found');
                return;
            }
            setSabreCounter(0);
            SabreMock.requestConsent(identifier, oneclick).then((res) =>
                setSabreResponse(res)
            );
            alert(`Consent request activated`);
        }
    }, [sabreCounter]);

    useEffect(() => {
        const neokeStatus = sabreResponse.error
            ? sabreResponse?.error?.status
            : sabreResponse?.value?.status;

        if (neokeStatus === 'empty') return;
        if (neokeStatus === 'ok') {
            const identifier = Cookies.get('identifier');

            const message =
                sabreResponse.value.walletResponse[0].response.response;

            let betterMessage = message;
            if (message === 'Ok')
                betterMessage = 'Invitation already sent to user, check cw';

            if (message === 'Invitation already sent to user, check inbox')
                betterMessage =
                    'Invitation already sent to user, check inbox and cw';

            alert(
                `Consent request flow start for ${identifier}: '${betterMessage}'`
            );
        } else
            alert(
                `Consent request FAILED:   ${sabreResponse?.error?.error?.message}`
            );

        setSabreResponse(defaultSabreResponse);
    }, [sabreCounter, sabreResponse]);

    const startConsentFlow = (oneclick) => {
        const identifier = Cookies.get('identifier');
        if (!identifier) {
            alert('No identifier found');
            return;
        }

        SabreMock.requestConsent(identifier, oneclick).then((res) =>
            setSabreResponse(res)
        );
        alert(`Consent request activated`);
    };

    return (
        <Container id="body">
            <Background style={style} onClick={() => setCounter(counter + 1)} />
            <Outlet />
            <div style={sabreEasterEgg}>
                <button
                    className="btn btn-primary btn-lg"
                    onClick={() => startConsentFlow(true)}
                >
                    1-Click Flow
                </button>
                <button
                    className="btn btn-primary btn-lg"
                    onClick={() => startConsentFlow(false)}
                >
                    Complete Flow
                </button>
            </div>
        </Container>
    );
};

export default Component;
