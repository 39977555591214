import { Login, Trigger } from 'component/common';

import S from 'theme/structure';

const {
    Header: { Container, Row, Col },
} = S;

const Component = () => (
    <header>
        <Container>
            <Row>
                <Col>
                    <Trigger.GoHomeLogo />
                </Col>
                <Col>
                    <Login.Button simpleLogin={true} />
                </Col>
            </Row>
        </Container>
    </header>
);

export default Component;
