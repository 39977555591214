import { Button } from 'component/common/login';
import { Panel, FormWrapper } from 'component/common';
import { Form } from 'react-bootstrap';
import LogoAirScout from '../../../images/air-scout.svg';
import GoBack from '../../../images/arrow-left.svg'
import { Main as Provider } from 'component/context';

import S from 'theme/structure';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const Login = () => {
    const [status, setStatus] = useState(false);
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const { setUser } = Provider.useContext();

    const goBack = () => {
        setEmail();
        setError();
        setStatus(false);
    };

    const onChange = (e) => {
        setError();
        setEmail(e.target.value);
    };

    const continueWithEmail = () => {
        if (!email || !emailRegex.test(email)) {
            setError("Please enter a valid email address");
            return;
        }
        Cookies.set("identifier", email, {
            path: '/',
            domain: 'neoke.com',
            secure: true
        });
        setUser({ identifier: email });
        navigate('/');
    };

    return <S.Container.Page className="login-wrapper">
        <div className="logo-wrapper">
            <img src={LogoAirScout} alt="AirScout" onClick={() => navigate('/')}/>
        </div>
        { status ? 
            <Panel header="Continue to AirScout">
                <img src={GoBack} alt="Go Back" className="go-back" onClick={goBack} />
                <FormWrapper.Row label="Email" error={error}>
                    <Form.Control
                        id="email"
                        type="text"
                        placeholder="e.g. name@example.com"
                        value={email}
                        onChange={onChange}
                        isInvalid={error}
                    />
                </FormWrapper.Row>
                <S.Form.Buttons.Container className="login-form-button">
                    <S.Form.Buttons.Button size="lg" onClick={continueWithEmail}>
                        Continue
                    </S.Form.Buttons.Button>
                </S.Form.Buttons.Container>
            </Panel> :
            <Panel header="Welcome to AirScout">
                <div className="sub-title">Your travel journey starts here</div>
                <S.Form.Buttons.Container>
                    <S.Form.Buttons.Button size="lg" onClick={() => setStatus(true)}>
                        Continue with Email
                    </S.Form.Buttons.Button>
                    <Button size="lg" variant="primary" label="Continue with Travel Wallet"/>
                </S.Form.Buttons.Container>
            </Panel>
        }
    </S.Container.Page>
};

export default Login;